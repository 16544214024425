/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import PropertiesList from '../../../modules/profile/components/properties/PropertiesList'

type Props = {
  className: string
}

const DashboardLinks: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const user_data = JSON.parse(localStorage.getItem('user_data') as string)

  useEffect(() => {

    return () => {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef])

  return (

    <div className={`card ${className}`}>
      <PropertiesList />
      <div className='position-relative bgi-size-cover pt-5 p-5 mt-2 row bgi-no-repeat bgi-size-cover custom-mw-100'>
        <div className="flex-center mt-10 custom-mw-100">
          <h3 className='text-gray-600 text-center'>Here you can access recent activity</h3>
          <p className='text-center fs-6 text-prturq'>Click on the icons below for more information</p>
        </div>
      </div>
      <div className='position-relative bgi-size-cover pt-5 p-5 row bgi-no-repeat bgi-size-cover custom-mw-100'>
      <div className='card-body p-0 custom-mw-100'>
        {/* begin::Stats */}
          <div className='row'>
          {/* begin::Row */}
            {/* begin::Col */}
            <div className='col-4 p-0'>
            <Link to='/profile/properties' className='text-gray-800 fw-bold fs-6'>
              <div className='bg-white shadow px-1 py-6 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/property.svg'
                className='svg-icon-3x svg-icon-prturq d-block my-2'
              />
              <div className='text-gray-800 text-center'>
                Properties
              </div>
              </div>
            </Link>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-4 p-0'>
            <Link to='/documents' className='text-gray-800 fw-bold fs-6'>
              <div className='bg-white shadow px-1 py-6 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/documents.svg'
                className='svg-icon-3x svg-icon-prgreen d-block my-2'
              />
              <div className='text-gray-800 text-center'>
                Documents
              </div>
              </div>
            </Link>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-4 p-0'>
            <Link to='/forms/valuation' className='text-gray-800 fw-bold fs-6'>
              <div className='bg-white shadow px-1 py-6 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/valuations.svg'
                className='svg-icon-3x svg-icon-prturq d-block my-2'
              />
              <div className='text-gray-800 text-center'>
                Valuations
              </div>
              </div>
            </Link>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          <div className='row'>
            {/* begin::Col */}
            <div className='col-4 p-0'>
            <Link to='/work-orders' className='text-gray-800 fw-bold fs-6'>
              <div className='bg-white shadow px-1 py-6 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/workorders.svg'
                className='svg-icon-3x svg-icon-prgreen d-block my-2'
              />
              <div className='text-gray-800 text-center'>
                Work Orders
              </div>
              </div>
            </Link>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-4 p-0'>
            <Link to='/recent-activity' className='text-gray-800 fw-bold fs-6'>
              <div className='bg-white shadow px-1 py-6 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/tick.svg'
                className='svg-icon-3x svg-icon-prturq d-block my-2'
              />
               <div className='text-gray-800 text-center'>
                Recent Activity
              </div>
              </div>
            </Link>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-4 p-0'>
            <Link to='/forms/contact' className='text-gray-800 fw-bold fs-6'>
              <div className='bg-white shadow px-1 py-6 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/contact.svg'
                className='svg-icon-3x svg-icon-prgreen d-block my-2'
              />
              <div className='text-gray-800 text-center'>
                Contact
              </div>
              </div>
            </Link>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
      </div>
      </div>
      {/*
      <div className='position-relative bgi-size-cover pt-5 p-5 mt-2 row bgi-no-repeat bgi-size-cover min-h-350px custom-mw-100'
          style={{
            backgroundImage: `url('${toAbsoluteUrl('/media/prospect/reception-girl-3.jpg')}')`,
          }}
          >
              <div className='d-flex flex-column col-6'>

              </div>
              <div className='d-flex flex-column text-center text-white col-6 justify-content-center'>
                <h3 className='text-white'>CONTACT US</h3>
                <p className='fs-6'>Contact Prospect for all of your property&nbsp;needs</p>
                <Link to='/forms/contact'
                 className='btn btn-lg btn-primary w-100 mb-5'
                >
                  CONTACT
                </Link>
              </div>
          </div>
      {/* end::Stats */}
      </div>
  )
}


export {DashboardLinks}
