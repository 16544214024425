/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import React, {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../../assets/ts/components'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {pathname} = useLocation()
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const
  toolbarIconClass = 'svg-icon-3x svg-icon-prgrey d-block my-2',
  toolbarButtonClass = 'svg-icon-1'
  const isChatRead = localStorage.getItem('chat_read') as string;

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    <div
      id='kt_header'
      className={clsx('header p-3 px-4 pb-8 d-md-none', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none w-100' title='Show aside menu'>
            <Link to='/profile/properties' className='text-gray-800 fw-bold flex-grow-1 fs-8' style={{flex: '1 0 20%'}}>
              <div className='px-1 py-1 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/property.svg'
                className={toolbarIconClass}
              />
              <div className='text-gray-800 text-center'>
                Properties
              </div>
              </div>
            </Link>
            <Link to='/documents' className='text-gray-800 fw-bold fs-8 flex-grow-1' style={{flex: '1 0 20%'}}>
              <div className='px-1 py-1 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/documents.svg'
                className={toolbarIconClass}
              />
              <div className='text-gray-800 text-center'>
                Documents
              </div>
              </div>
            </Link>
            <Link to='/recent-activity' className='text-gray-800 fw-bold fs-8 flex-grow-1' style={{flex: '1 0 20%'}}>
              <div className='px-1 py-1 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/tick.svg'
                className={toolbarIconClass}
              />
               <div className='text-gray-800 text-center'>
                Recent Activity
              </div>
              </div>
            </Link>
            <div
              className=' text-gray-800 fw-bold fs-8 flex-grow-1'
              id='kt_drawer_chat_toggle' style={{flex: '1 0 20%'}}
            >
              <div className='px-1 py-1 mx-1 mb-2 text-center'>
              <KTSVG
                path='/media/icons/prospect/speech.svg'
                className={toolbarIconClass}
              />
              <div className='text-gray-800 text-center'>
                Chat
              </div>
              </div>
              {
                isChatRead && <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' id='chat_unread'></span>                       
              }
              <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink none' id='chat_unread' style={{"display":"none"}}></span>
            
            </div>
            <div
              className='btn btn-icon btn-active-light-primary fs-8 flex-grow-1'
              id='kt_aside_mobile_toggle' style={{flex: '1 0 20%'}}
            >
              <div className='px-1 py-1 mx-1 mb-2 text-center'>
              <KTSVG path='/media/icons/prospect/menu.svg' className={toolbarIconClass} />
              <div className='text-gray-800 text-center'>
                Menu
              </div>
              </div>
            </div>
          </div>
        )}
        {/* end::Aside mobile toggle */}
        {/* begin::Logo */}

        {!aside.display && (
          <>
            <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
              <Link to='/dashboard' className='d-lg-none'>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/favicon.png')} className='h-30px' />
              </Link>
            </div>
            <div className='d-flex align-items-stretch flex-shrink-0'>
              <Topbar />
            </div>
          </>
        )}
        {/* end::Logo */}

        {/* begin::Wrapper 
        <div className='d-flex align-items-stretch justify-content-end flex-lg-grow-1'>
          {/* begin::Navbar 
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              <Header />
            </div>
          )}
          

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}
                  

        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  )
}
