/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react'
import {IconUserModel} from '../../ProfileModels'
import axios from 'axios';
import Loader from "react-loader-spinner";
import PropertyItem from './../PropertyItem';

const API_URL = process.env.REACT_APP_SERVER_HOST;
const GET_PROPERTY_URL =`${API_URL}property`;

var _self: PropertiesList;

class PropertiesList extends Component<any, any> {

    constructor(props: any){
        super(props);

        const reapit_id = localStorage.getItem('reapit_id');

        this.state = {
            propertyData: [],
            propertyImagesObj: [],
            pageNumber: 1,
            pageSize: 60000,
            pageCount: 9,
            totalPageCount: 500,
            totalCount: 0,
            isLoader: true,
            reapit_id: reapit_id
        };
        _self = this;
        this.fetchProperty = this.fetchProperty.bind(this);
    }

    componentDidMount(){
        const requestBody = {
            "request_to":"reapit",
            "pageSize": 60000,
            "pageNumber": 1
        };
        this.fetchProperty(requestBody);
    }

    fetchProperty(requestBody){
        requestBody.landlordId = this.state.reapit_id || '';
        _self.setState({
            isLoader: true,
        })
        axios.get(
    `${GET_PROPERTY_URL}`,{ params: requestBody})
        .then(function(response) {
            if(response.status === 200){
                _self.setState({
                    propertyData: Array.isArray(response.data._embedded) ? response.data._embedded : [],
                    pageNumber: response.data.pageNumber ? response.data.pageNumber : 1,
                    pageSize: response.data.pageSize ? response.data.pageSize : 9,
                    pageCount: response.data.pageCount ? response.data.pageCount : 9,
                    totalPageCount: response.data.totalPageCount ? response.data.totalPageCount : 0,
                    totalCount: response.data.totalCount ? response.data.totalCount : 0,
                    propertyIdList: response.data.propertyIdList ? response.data.propertyIdList : '',
                    isLoader: false,
                });
                localStorage.setItem('propertyIdList',response.data.propertyIdList)
            }
        });
    }

    createPagination = (totalPageCount) => {
        var pageCollection:any[] = [];
        for(let pageCount = 1; pageCount <= totalPageCount; pageCount++){
            pageCollection.push(
                <li className={this.state.pageNumber === pageCount ? "page-item active" : "page-item" }>
                    <a href='#' className="page-link" onClick={ (event) => this.onPageSelect(event , pageCount) }>
                        {pageCount}
                    </a>
                </li>
            );
        }
        return pageCollection;
    }

    onPageSelect = (event,pageCount) => {
        event.preventDefault();
        const requestBody = {
            "request_to":"reapit",
            "pageSize": 9,
            "pageNumber": pageCount,
        };
        this.fetchProperty(requestBody);

        var activePageElement = document.querySelectorAll('.page-item.active');
        activePageElement[0].classList.remove('active');
        event.currentTarget.parentElement.classList.add("active");
    }

    render(){
        const propertyData = this.state.propertyData;
        return (
            <>
                {
                    this.state.isLoader == true ?
                        <div className='propertyLoader' style={{"textAlign":"center","margin":"50px"}}>
                            <Loader
                                type="Oval"
                                color="#B0E670"
                                height={150}
                                width={150}
                            />
                        </div>
                    :
                    <>
                    <div className="container p-6">
                        <div className='row g-6 g-xl-9 p-5'>
                            {
                                Object.keys(propertyData).length !== 0 ?
                                    propertyData.map( (propertyData,propertyKey) => {
                                        var propertyId = propertyData.id;
                                        var propertyAddress = propertyData.address;
                                        var propertyType = propertyData.letting ? "Currently Rented" : "For Sale";
                                        var propertyPrice = propertyData.letting ? propertyData.letting.rent : propertyData.selling ? propertyData.selling.price : 0 ;
                                        let nf = new Intl.NumberFormat('en-US');
                                        propertyPrice = nf.format(propertyPrice);
                                        var propertyCurrency = '£';
                                        var propertyDate = new Date(propertyData.created).toLocaleString('en-us',{month:'long', day:'numeric', year:'numeric'});
                                        var propertyImage = propertyData.featuredImageUrl;
                                        var landlordId = propertyData.letting.landlordId;

                                        return(
                                            <div className='col-md-6 col-xl-4' key={propertyKey}>
                                                <PropertyItem
                                                        key={propertyKey}
                                                        id={propertyId}
                                                       icon={propertyImage}
                                                       badgeColor='primary'
                                                       status={propertyType}
                                                       statusColor='primary'
                                                       title={ propertyAddress.buildingName + ' ' + propertyAddress.buildingNumber + ' ' + propertyAddress.line1 + ' ' + propertyAddress.line2}
                                                       description={propertyData.strapline}
                                                       negotiatorId={propertyData.negotiatorId}
                                                       landlordId={landlordId}
                                                       date={propertyDate}
                                                       budget= {propertyCurrency + propertyPrice}
                                                       progress={50}
                                                />
                                            </div>
                                        )
                                    })
                                    : null
                            }
                        </div>
                        <div className='d-flex flex-stack flex-wrap p-5'>
                            <div className='fs-6 fw-bold text-gray-700'>
                                Showing
                                {" " + this.state.pageNumber + " " } to
                                {" " + this.state.pageCount * this.state.pageNumber + " "} of
                                {" " + this.state.pageCount + " "} entries
                            </div>
                            <ul className='pagination'>
                                {this.createPagination(this.state.totalPageCount)}
                            </ul>
                        </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default PropertiesList;

const users1: Array<IconUserModel> = [
  {name: 'Emma Smith', avatar: '/media/avatars/150-1.jpg'},
  {name: 'Rudy Stone', avatar: '/media/avatars/150-2.jpg'},
  {name: 'Susan Redwood', initials: 'S', color: 'primary'},
]
