import React, {Component} from "react";
import {Link, withRouter} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'


var _self: PageHeader

class PageHeader extends Component<any, any> {
    constructor(props) {
        super(props)
        this.state = props;
        this.state = {
          title: this.props.title,
          className: []
        }
    
        _self = this
    
      }
      render() {
        const className = this.props.className
        const title = this.props.title
        return (
            <>
            <div className={`card ${className} d-flex flex-wrap`}>
                {/* begin::Stats */}
                <div className='card position-relative text-left pt-1 rounded-0'>
                {/* begin::Row */}
                <div className="d-flex text-left align-items-center ps-5">
                <div className='fs-3 lh-1 fw-bolder w-100'>
                   {title}
                    </div>
                </div>
                
                {/* end::Row */}
                </div>
                {/* end::Stats */}
            </div>
            </>
        )
      }
}

export default withRouter(PageHeader)